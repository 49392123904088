import React from "react";
import Image from "next/legacy/image";
import { AccountConnectionStatus } from "@domain/account/external-connections/types";
import styles from "./index.module.scss";

type Props = {
  lockStatus: AccountConnectionStatus;
  onClick: () => void;
  forbidden: boolean;
};

export const LockUi = ({ lockStatus, onClick, forbidden }: Props) => {
  return (
    <div className={styles.container}>
      {lockStatus === AccountConnectionStatus.FORBIDDEN && (
        <div className={styles.wrapper}>
          <div className={styles.iconWrapper}>
            <Image
              src={"/svg/padlock-closed.svg"}
              alt="closed padlock"
              width={19}
              height={19}
            />
          </div>
          <div className={styles.iconDesc}>
            {forbidden ? "Pieeja liegta" : "Nav pieejams"}
          </div>
        </div>
      )}

      {lockStatus === AccountConnectionStatus.UPDATE && (
        <div className={styles.wrapperAllowed} onClick={onClick}>
          <div className={styles.iconWrapper}>
            <Image
              src={"/svg/refresh-icon.svg"}
              alt="refresh"
              width={19}
              height={19}
            />
          </div>
          <div className={styles.iconDesc}>Atjaunot</div>
        </div>
      )}

      {(lockStatus === AccountConnectionStatus.ALLOWED ||
        lockStatus === AccountConnectionStatus.CONNECTED) && (
        <div className={styles.wrapperAllowed} onClick={onClick}>
          <div className={styles.iconWrapper}>
            {lockStatus === AccountConnectionStatus.ALLOWED && (
              <Image
                src={"/svg/padlock-open.svg"}
                alt="opened padlock"
                width={19}
                height={19}
              />
            )}
            {lockStatus === AccountConnectionStatus.CONNECTED && (
              <Image
                src={"/svg/chain-locked.svg"}
                alt="locked chain"
                width={17}
                height={17}
              />
            )}
          </div>
          <div className={styles.iconDesc}>
            {lockStatus === AccountConnectionStatus.ALLOWED
              ? "Autorizējies"
              : "Autorizēts"}
          </div>
        </div>
      )}
    </div>
  );
};
