import React from "react";
import styles from "./index.module.scss";

export type Props = {
  accumulatedMoney?: number;
  onMoneyClick?: () => void;
};

export const AvailableForPayout = ({
  accumulatedMoney,
  onMoneyClick,
}: Props) => {
  return (
    <div className={styles.accumulatedMoney} onClick={onMoneyClick}>
      <span className={styles.accumulatedDesc}>Pieejams</span>
      <span
        className={styles.accumulatedHighlight}
      >{`${accumulatedMoney} €`}</span>
    </div>
  );
};
