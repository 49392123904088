import React, { FC } from "react";
import classnames from "classnames";
import VideoIcon from "@public/svg/video-icon.svg";
import styles from "./index.module.scss";

type Props = {
  count: number;
  firstTitle: string;
  secondTitle: string;
  onClickFirstPart: () => void;
  onClickSecondPart: () => void;
  lessonId: string;
};

export const CourseModuleItemMerged: FC<Props> = ({
  count,
  firstTitle,
  secondTitle,
  onClickFirstPart,
  onClickSecondPart,
  lessonId,
}) => {
  const fixedCount = parseInt(lessonId, 10) < 2 ? count : count + 1;
  return (
    <div className={styles.container}>
      <div className={styles.numberWrapper}>
        <div
          className={classnames(styles.number, {
            [styles.currentLesson]: `0${fixedCount}` === lessonId,
          })}
        >
          {count}
        </div>
      </div>

      <button onClick={onClickFirstPart} className={styles.firstPartWrapper}>
        <div className={styles.videoIconWrapper}>
          <VideoIcon />
        </div>
        <div className={styles.lessonTitle}>{firstTitle}</div>
      </button>

      <button onClick={onClickSecondPart} className={styles.secondPartWrapper}>
        <div className={styles.videoIconWrapper}>
          <VideoIcon />
        </div>
        <div className={styles.lessonTitle}>{secondTitle}</div>
      </button>
    </div>
  );
};
