import Image from "next/legacy/image";
import { DatePicker } from "../DatePickerFns";
import styles from "./index.module.scss";

export interface Props {
  onChange: (value: Date | null) => void;
  value: string | undefined;
}

export const InputCalendar = ({ onChange, value }: Props) => {
  return (
    <div className={styles.wrapper} suppressHydrationWarning>
      <DatePicker
        format="DD/MM/YYYY"
        onChange={onChange}
        suffixIcon={
          <Image
            src={"/svg/calendar.svg"}
            width={24}
            height={24}
            alt="calender image"
          />
        }
        placeholder="dd/mm/yyyy"
        value={new Date(value || 0)}
        allowClear={false}
      />
    </div>
  );
};
