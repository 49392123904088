import React from "react";
import styles from "./index.module.scss";

export const UnorderedListSmall: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <div className={styles.bulletPointsWrapper}>
      <ul>
        <li>{children}</li>
      </ul>
    </div>
  );
};
