import { PropsWithChildren } from "react";
import { clientSideEnvVariable } from "src/client-side-env";

export const DevEnvironmentComponent: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const dev = clientSideEnvVariable("DEV_ENV") === "true";
  if (!dev) {
    return <></>;
  }
  return <>{children}</>;
};
