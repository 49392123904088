import styles from "./index.module.scss";

export const HowToWithdraw = () => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Kā izņemt naudu?</h1>
      <h3 className={styles.subTitle}>
        Kā kārtīgi programmētāji - šo daļu vēl neesam pabeiguši 🥶
      </h3>
      <p className={styles.desc}>
        Ja būs interese no audzēkņu puses un <i>Piesaistes programma</i>{" "}
        darbosies kā iecerēts - pabeigsim arī šo sadaļu.
        <br />
        Līdz tam - droši raksti epastā / Discord un viss nopelnītais tiks
        izmaksāts atbilstoši noteikumiem.
      </p>
    </div>
  );
};
