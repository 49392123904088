import styles from "./index.module.scss";

export const DescriptionGroup = () => {
  return (
    <div className={styles.descriptionGroup}>
      <span className={styles.description}>Bez maksas</span>
      <span className={styles.description}>4 nodarbības</span>
      <span className={styles.description}>Iesācējiem</span>
    </div>
  );
};
