import { FC } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import Image from "next/image";
import { Button } from "../Button";
import styles from "./index.module.scss";

export type Props = {
  title: string;
  text: string;
  number: number;
  buttonTitle?: string;
  link?: string;
  successfullySubmitted?: string;
  status?: boolean;
};

export const TaskBanner: FC<Props> = ({
  title,
  text,
  number,
  buttonTitle,
  link,
  status,
  successfullySubmitted,
}) => {
  const router = useRouter();
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperWithButton]: buttonTitle && link,
        [styles.wrapperCompleted]: status,
      })}
    >
      <div className={styles.numberWrapper}>
        <span>{number}</span>
      </div>
      <div
        className={classNames(styles.mainWrapper, {
          [styles.mainWrapperCompleted]: status,
        })}
      >
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{status ? successfullySubmitted : text}</p>
      </div>
      {buttonTitle && link && !status && (
        <div className={styles.buttonWrapper}>
          <Button type="default" onClick={() => router.push(link)}>
            {buttonTitle}
          </Button>
        </div>
      )}
      {status && (
        <div className={styles.imageWrapper}>
          <Image
            src={"/svg/success-checkmark.svg"}
            alt="closed padlock"
            width={45}
            height={45}
          />
        </div>
      )}
    </div>
  );
};
