import React from "react";
import Image from "next/legacy/image";
import Link from "next/link";
import styles from "./index.module.scss";

export type Props = {
  title: string;
  desc: string;
  href: string;
};

export const GithubLink = ({ title, desc, href }: Props) => {
  return (
    <div className={styles.bannerWrapper}>
      <div>
        <Image
          src={"/svg/github.svg"}
          width={51}
          height={51}
          alt={"github image"}
        />
      </div>
      <div className={styles.textSide}>
        <h3>{title}</h3>
        <p>{desc}</p>
        <Link href={href} target={"_blank"} rel="noreferrer">
          Doties uz Github
        </Link>
      </div>
    </div>
  );
};
