import React, { FC } from "react";
import { UnorderedListSmall } from "@components/atoms/UnorderedListSmall";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

export interface StepComponentInterface {
  countNumber: number;
  title: string;
  button: { title: string; onClick: () => void };
  desc?: string[];
}

type Props = {
  stepComponent: StepComponentInterface;
};

export const StepComponent: FC<Props> = ({ stepComponent }) => {
  const { button, desc, title, countNumber } = stepComponent;

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.countWrapper}>
          <div className={styles.count}>{countNumber}</div>
        </div>
        <div className={styles.title}>{title}</div>
        {button && (
          <div className={styles.buttonWrapper}>
            <Button onClick={button.onClick}>{button.title}</Button>
          </div>
        )}
      </div>
      {desc && (
        <div className={styles.bottom}>
          {desc.map((item, i) => (
            <UnorderedListSmall key={i}>{item}</UnorderedListSmall>
          ))}
        </div>
      )}
    </div>
  );
};
