import React, { FC } from "react";
import GithubIcon from "@public/svg/github.svg";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

type Props = {
  onClickRedirectToGithub: () => void;
};

export const GithubBanner: FC<Props> = ({ onClickRedirectToGithub }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentContainer}>
        <div className={styles.iconWrapper}>
          <GithubIcon />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.titleWrapper}>
            <h4 className={styles.title}>
              Piekļuve mācību materiāliem ar Github
            </h4>
          </div>
          <div className={styles.descWrapper}>
            <p className={styles.desc}>
              Saņem piekļuvi mācību materiāliem autorizējoties ar Github. Pēc
              autorizācijas savā e-pastā saņemsi uzaicinājumu uz Github vietni.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button onClick={onClickRedirectToGithub}>Autorizēties</Button>
      </div>
    </div>
  );
};
