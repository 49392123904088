import React from "react";
import { GithubLink } from "@components/atoms/GithubLink";
import { formatDateNative } from "@utils/date-time/common";
import styles from "./index.module.scss";

interface Props {
  date: Date;
  html: string;
  githubUrl: string;
}

export const TermsAndConditions = ({ date, html, githubUrl }: Props) => {
  return (
    <>
      <div className={styles.wrapperConditions}>
        <h1 className={styles.titleConditions}>Noteikumi</h1>
        <div className={styles.githubWrapper}>
          <GithubLink
            title="Noteikumi ir spēkā no"
            desc={formatDateNative(date)}
            href={githubUrl}
          />
        </div>

        <div
          className={styles.descConditions}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </>
  );
};
