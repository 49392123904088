import Image from "next/image";
import styles from "./index.module.scss";

export const SuccessfullySubmittedAssignmentBanner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.topWrapper}>
        <div className={styles.numberAndStatusWrapper}>
          <div className={styles.numberWrapper}>
            <span>1</span>
          </div>
          <p className={styles.status}>IZPILDĪTS</p>
        </div>
        <Image
          src={"/svg/success-checkmark.svg"}
          alt="closed padlock"
          width={45}
          height={45}
        />
      </div>
      <div className={styles.bottomWrapper}>
        <p className={styles.title}>Mājasdarbs iesniegts!</p>
        <p className={styles.text}>
          Paldies! Tavs mājasdarbs ir veiksmīgi iesniegts. Ja nepaspēji
          iepazīties ar citiem mājasdarbiem, vari to izdarīt šeit.
        </p>
      </div>
    </div>
  );
};
