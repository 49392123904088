import React, { FC } from "react";
import { Tooltip } from "antd";
import DiscordIcon from "@public/svg/discord.svg";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

type Props = {
  onClickRedirectToDiscord: () => void;
  tooltipText: string;
};

export const DiscordBanner: FC<Props> = ({
  onClickRedirectToDiscord,
  tooltipText,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentContainer}>
        <div className={styles.contentWrapper}>
          <div className={styles.titleWrapper}>
            <h4 className={styles.title}>Grupas čats Discord vietnē</h4>
          </div>
          <div className={styles.iconWrapper}>
            <DiscordIcon />
          </div>
        </div>
        <div className={styles.descWrapper}>
          <p className={styles.desc}>
            Intensīvā kursa grupas mācības sāksies tad, kad grupa būs pilnībā
            nokomplektēta. Seko tam līdzi Discord čatā &quot;Apstiprinātie
            kandidāti&quot;.
          </p>
        </div>
      </div>
      <Tooltip title={tooltipText} placement="left">
        <div className={styles.buttonWrapper}>
          <Button
            disabled={tooltipText !== ""}
            onClick={onClickRedirectToDiscord}
          >
            Uz Discord
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};
