import { embeddedVideoSrc } from "src/utils/youtube";

export interface Props {
  title?: string;
  src: string;
}

export const EmbeddedYouTubeVideo: React.FC<Props> = ({ title, src }) => {
  return (
    <iframe
      title={title}
      src={embeddedVideoSrc({ src })}
      style={{ width: "100%", height: "100%", borderRadius: "10px" }}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      referrerPolicy="same-origin"
    />
  );
};
