import { z } from "zod";

export interface AcceptJobOfferInput {
  id: string;
  employmentStartDate: string;
}

export enum JobOfferStatus {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
}

export type Data = {
  jobOffers: JobOffer[];
};

export type JobOffer = {
  id: string;
  company: string;
  email?: string | null;
  phoneNumber?: string | null;
  salary: number;
  status: JobOfferStatus;
  employmentStartDate?: string | null;
  closed?: string | null;
  created: string;
};

export const AcceptingJobOfferSchema = z.object({
  employmentStartDate: z.string(),
});

export type AcceptingJobOffer = z.infer<typeof AcceptingJobOfferSchema>;

export const JobOfferStatusSchema = z.enum([
  JobOfferStatus.ACCEPTED,
  JobOfferStatus.REJECTED,
  JobOfferStatus.PENDING,
]);

export const AddJobOfferDetailsInputSchema = z.object({
  company: z.string(),
  email: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  salary: z.number(),
  status: JobOfferStatusSchema,
  employmentStartDate: z.string().nullable(),
});

export type AddJobOfferDetailsInput = z.infer<
  typeof AddJobOfferDetailsInputSchema
>;

export const UpdateJobOfferInputSchema = z.object({
  company: z.string(),
  email: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  salary: z.number(),
  status: JobOfferStatusSchema,
  employmentStartDate: z.string().nullable(),
});

export type UpdateJobOfferInput = z.infer<typeof UpdateJobOfferInputSchema>;
