import React, { useState, useRef } from "react";
import Image from "next/legacy/image";
import { Modal } from "antd";
import { EmbeddedYouTubeVideo } from "@components/atoms/EmbeddedYouTubeVideo";
import styles from "./index.module.scss";

export type Props = {
  lessonTitle: string;
  src: string;
};

export const PreviousLessonVideo: React.FC<Props> = ({ src, lessonTitle }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const previewRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  const showModal = () => {
    previewRef.current.scrollIntoView({ behavior: "smooth" });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.previewWrapper}
        onClick={showModal}
        ref={previewRef}
      >
        <Image
          className={styles.preview}
          src={"/svg/video-icon.svg"}
          alt={lessonTitle}
          loading="lazy"
          width={30}
          height={29}
        />
        <h3 className={styles.title}>{lessonTitle}</h3>
      </div>
      {isModalVisible && (
        <Modal
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          className={styles.modal}
          width={966}
        >
          <div className={styles.iframeWrapper}>
            <EmbeddedYouTubeVideo src={src} />
          </div>
        </Modal>
      )}
    </div>
  );
};
