import React, { FC } from "react";
import CodelexIcon from "@public/svg/codelex-logo.svg";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

type Props = {
  onClick?: () => void;
  title: string;
  desc: string;
  buttonTitle: string;
  link: string;
};

export const AssignmentBanner: FC<Props> = ({
  onClick,
  title,
  desc,
  buttonTitle,
  link,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.desc}>{desc}</div>
      <div className={styles.actionWrapper}>
        <div className={styles.buttonWrapper}>
          <Button onClick={onClick} type="link" href={link}>
            {buttonTitle}
          </Button>
        </div>
        <div className={styles.iconWrapper}>
          <CodelexIcon />
        </div>
      </div>
    </div>
  );
};
