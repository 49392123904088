import React from "react";
import Image from "next/legacy/image";
import classnames from "classnames";
import { IntroCourseLesson } from "@domain/intro-course/types";
import { formatDateToUtcNative } from "@utils/date-time/common";
import styles from "./index.module.scss";

type Props = {
  calendar: IntroCourseLesson[];
  calendarModalAvailable?: boolean;
  onShowMoreClick?: () => void;
};

const months = [
  "Janvāra",
  "Februāra",
  "Marta",
  "Aprīļa",
  "Maija",
  "Jūnija",
  "Jūlija",
  "Augusta",
  "Septembra",
  "Oktobra",
  "Novembra",
  "Decembra",
];

export const CalendarSchedule = ({
  calendar,
  calendarModalAvailable,
  onShowMoreClick,
}: Props) => {
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.shadowWrapper]: calendarModalAvailable,
      })}
    >
      <div className={styles.headerWrapper}>
        <Image
          src={"/svg/calendar-time.svg"}
          width={46}
          height={45}
          alt={"Calendar"}
        />
        <div className={styles.title}>Gaidāmie Ievadkursa norises laiki</div>
      </div>
      <div
        className={classnames(styles.graphTable, {
          [styles.graphTableHidden]: calendarModalAvailable,
        })}
      >
        {calendar.map((row, index) => (
          <div key={row.date} className={styles.box}>
            <p className={styles.date}>{formatDateToUtcNative(row.date)}</p>
            <p className={styles.time}>
              {row.startTime}-{row.endTime}
            </p>
            <p className={styles.lessonTitle} suppressHydrationWarning>
              {index === 0 || index === 4
                ? `${months[new Date(row.date).getMonth()]} Ievadkursi`
                : "Tiešsaistes nodarbība"}
            </p>
          </div>
        ))}
        {calendarModalAvailable && (
          <div className={styles.blurryBackground}>
            <button className={styles.showMoreButton} onClick={onShowMoreClick}>
              Skatīt visu
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
