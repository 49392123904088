import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { formatSeconds } from "@utils/date-time/common";
import styles from "./index.module.scss";

type Props = {
  initialDuration: number;
  intervalMs: number;
  timeFormat: string;
  onExpired: () => void;
};

export const CountDown = ({
  initialDuration,
  intervalMs,
  timeFormat,
  onExpired,
}: Props) => {
  const [duration, setDuration] = useState(initialDuration);
  const router = useRouter();
  useEffect(() => {
    if (duration <= 1) {
      onExpired();
      return;
    }
    const interval = setInterval(() => {
      setDuration(duration - 1);
    }, intervalMs);
    return () => clearInterval(interval);
  }, [duration, intervalMs, router, onExpired]);
  return (
    <div className={styles.duration}>
      00:{formatSeconds(duration, timeFormat)}
    </div>
  );
};
