import React from "react";
import CodelexIcon from "@public/svg/codelex-logo.svg";
import styles from "./index.module.scss";

export const SignInProcessing = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <CodelexIcon />
        </div>
        <div>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title} data-testid="signing-in">
              Pierakstīšanās...
            </h1>
          </div>
          <div className={styles.descWrapper}>
            <span className={styles.desc}>
              Lūdzu, uzgaidiet, kamēr apstrādājam Jūsu pieprasījumu. Šis process
              var aizņemt līdz 1 minūtei.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
