import { ReactNode } from "react";
import { Tooltip } from "antd";
import classNames from "classnames";
import { Button } from "@components/atoms/Button";
import { UploadAgreementContainer } from "@containers/customer/UploadAgreementContainer";
import styles from "./index.module.scss";
import type { ButtonType } from "antd/lib/button";

export interface Props {
  refetch?: () => Promise<unknown> | undefined;
  title?: string;
  passive?: boolean;
  description?: string;
  type: "default" | "secondary";
  caption?: string;
  openNewTab?: boolean;
  uploadButton?: {
    show: boolean;
    accountId: string;
    url?: string;
    title: string;
    disabled: boolean;
    toolTipDescription: string;
  };
  button?: {
    type: ButtonType;
    title: string;
    disabled?: boolean;
    onClick?: () => void;
    icon?: ReactNode;
    href?: string;
  };
}

export const Step: React.FC<Props> = ({
  title,
  description,
  type,
  caption,
  button,
  uploadButton,
  refetch,
  openNewTab,
  passive: stepPassive,
}) => {
  return (
    <div
      className={classNames(styles.step, {
        [styles.secondaryStep]: type === "secondary",
        [styles.defaultStep]: type === "default",
      })}
    >
      <div className={styles.wrapper}>
        <h3
          className={classNames(styles.title, {
            [styles.titleSecondary]: type === "secondary",
            [styles.titleDefault]: type === "default",
          })}
        >
          {stepPassive ? (
            <span className={styles.passiveTitle}>{title}</span>
          ) : (
            <>{title}</>
          )}
        </h3>

        <p
          className={classNames(styles.description, {
            [styles.withoutTitle]: !title,
          })}
        >
          {stepPassive ? (
            <span className={styles.descriptionPassive}>{description}</span>
          ) : (
            description
          )}
        </p>

        {caption && (
          <h4
            className={classNames(styles.caption, {
              [styles.captionSecondary]: type === "secondary",
              [styles.captionDefault]: type === "default",
            })}
          >
            {caption}
          </h4>
        )}

        {button && (
          <div className={styles.btnWrapper}>
            <Button
              href={button.href}
              type={button.type}
              icon={button.icon}
              target={openNewTab ? "_blank" : "_self"}
              rel="noreferrer"
              onClick={() => {
                if (!button.onClick) {
                  return;
                }
                button.onClick();
              }}
              disabled={button.disabled}
            >
              {button.title}
            </Button>
          </div>
        )}
        {uploadButton?.show && (
          <Tooltip title={`${uploadButton.toolTipDescription}`}>
            <div className={styles.showUploadWrapper}>
              <UploadAgreementContainer
                refetch={refetch}
                uploadButtonTitle={uploadButton.title}
                uploadDisabled={uploadButton.disabled}
                uploadUrl={uploadButton.url}
              />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
