import React from "react";
import styles from "./index.module.scss";

export const ComponentSpinner = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.ldsSpinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
