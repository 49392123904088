import React, { FC } from "react";
import { DatePicker, Form } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { JobOffer, JobOfferStatus } from "@domain/job-offers";
import { Button } from "@components/atoms/Button";
import CalendarIcon from "@public/svg/calendar.svg";
import Portfolio from "@public/svg/portfolio-small.svg";
import {
  defaultCalendarFormat,
  defaultCalendarPlaceholder,
} from "@constants/calendarFormat";
import styles from "./index.module.scss";

export type AddJobOfferFormOutput = {
  companyName: string;
  companyEmail: string;
  phoneNumber: string;
  salary: number;
  takeOffer: string;
  employmentStartDate: string;
};

export type AcceptJobOfferFormOutput = {
  id: string;
  employmentStartDate: string;
};

export type Props = {
  onAcceptJobOffer: (values: AcceptJobOfferFormOutput) => void;
  loading: boolean;
  onClose: () => void;
  jobOffer?: JobOffer;
};

export const styleConfig = {
  height: "55px",
  borderRadius: "10px",
  paddingLeft: "12px",
};

const styleDatePickerConfig = {
  ...styleConfig,
  marginTop: "14px",
};

export const AcceptJobOffer: FC<Props> = ({
  onAcceptJobOffer,
  onClose,
  loading,
  jobOffer,
}) => {
  const [form] = Form.useForm();

  if (
    jobOffer?.status === JobOfferStatus.REJECTED ||
    jobOffer?.status === JobOfferStatus.PENDING
  ) {
    form.setFieldValue("employmentStartDate", undefined);
  }

  if (jobOffer?.status === JobOfferStatus.ACCEPTED) {
    form.setFieldValue("employmentStartDate", jobOffer?.employmentStartDate);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.close} onClick={onClose}>
        <CloseOutlined
          style={{
            fontSize: "15px",
            color: "#fff",
          }}
        />
      </div>
      <div className={styles.portfolioWrapper}>
        <Portfolio className={styles.portfolioIcon} />
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.descWrapper}>
          <span className={styles.cardDesc}>{jobOffer?.company}</span>
        </div>
        <Form
          form={form}
          initialValues={{
            id: jobOffer?.id,
            employmentStartDate: jobOffer?.employmentStartDate,
          }}
          style={{ paddingTop: "15px" }}
          wrapperCol={{ span: 24 }}
          onFinish={onAcceptJobOffer}
        >
          <span
            className={styles.startDateDesc}
            data-testid="enter-employment-start-date-title"
          >
            Lūdzu norādi, kad sāksi strādāt
          </span>
          <Form.Item
            name="employmentStartDate"
            getValueFromEvent={(onChange) =>
              onChange.format(defaultCalendarFormat)
            }
            valuePropName={"date"}
            rules={[
              {
                required: true,
                message: "Ievadi darba sākšanas datumu!",
              },
            ]}
          >
            <DatePicker
              style={styleDatePickerConfig}
              allowClear={false}
              suffixIcon={
                <div className={styles.calendarIconWrapper}>
                  <CalendarIcon />
                </div>
              }
              placeholder={defaultCalendarPlaceholder}
              data-testid="enter-employment-start-date"
            />
          </Form.Item>
          <Form.Item>
            <div className={styles.buttonWrapper}>
              <Button type="default" htmlType="submit" disabled={loading}>
                Iesniegt datumu
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
