import React, { ReactNode } from "react";
import Image from "next/legacy/image";
import Link from "next/link";
import { ReferralNavigation } from "@domain/referrals/csr";
import styles from "./index.module.scss";

interface Card {
  title: string;
  path: string;
  icon?: ReactNode;
  render: () => ReactNode;
  renderAffix?: () => ReactNode;
}

export type Props = {
  clicks: number;
  balance: number;
  navigate: ReferralNavigation;
};

export const ReferralsStatisticsCards = ({
  clicks,
  balance,
  navigate,
}: Props) => {
  const cards: Card[] = [
    {
      title: "Apmeklējumi",
      path: navigate.statistics,
      render: () => clicks,
    },
    {
      title: "Pieejams",
      path: navigate.withdraw,
      render: () => balance,
      renderAffix: () => <div className={styles.currency}>€</div>,
    },
    {
      title: "Sagataves",
      path: navigate.templates,
      render: () => (
        <Image
          src={"/svg/message.svg"}
          alt="message icon"
          width={59}
          height={59}
        />
      ),
    },
  ];
  return (
    <>
      <div className={styles.container}>
        {cards.map(({ title, path, render, renderAffix }, i) => (
          <Link key={i} href={path}>
            <div className={styles.wrapper}>
              <div key={title} className={styles.title}>
                {title}
              </div>
              <div className={styles.value}>
                {render()}
                {renderAffix && renderAffix()}
              </div>
              <div className={styles.link}>
                <Link href={path} className={styles.decoration}>
                  {title}
                </Link>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};
