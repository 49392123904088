const ALL_EXTERNAL_CONNECTION_TYPES = ["discord", "leet"] as const;

export type ExternalConnectionType =
  (typeof ALL_EXTERNAL_CONNECTION_TYPES)[number];

export interface AccountExternalConnection {
  name: ExternalConnectionType;
  connected: boolean;
  forbidden: boolean;
  details: DiscordConnectionDetails | null;
}

export interface DiscordConnectionDetails {
  joiningUrl: string | null;
}

export enum AccountConnectionStatus {
  FORBIDDEN = "FORBIDDEN",
  ALLOWED = "ALLOWED",
  CONNECTED = "CONNECTED",
  UPDATE = "UPDATE",
}
