import classnames from "classnames";
import { GoBackButton } from "@components/atoms/GoBackButton";
import { HeaderLogo } from "@components/atoms/HeaderLogo";
import styles from "./index.module.scss";

export interface Props {
  level: number;
  turns?: number;
  points?: number;
  lifes?: number;
  exitQuiz: string;
}

export const NavMenuForGame = ({
  level,
  turns,
  points,
  lifes,
  exitQuiz,
}: Props) => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <HeaderLogo link="/" />
        </div>
        <div className={styles.timerWrapper}>
          <div className={styles.timerText}>
            {points != undefined && (
              <>
                <div>
                  Punkti:{" "}
                  <span className={classnames(styles.numberWrapper)}>
                    {points}
                  </span>
                </div>
                <div>
                  Līmenis:{" "}
                  <span className={classnames(styles.numberWrapper)}>
                    {level} no 5
                  </span>
                </div>
              </>
            )}
            {turns != undefined && (
              <>
                <div>
                  Līmenis:{" "}
                  <span className={classnames(styles.numberWrapper)}>
                    {level} no 10
                  </span>
                </div>
                <div>
                  Gājienu skaits:{" "}
                  <span className={classnames(styles.numberWrapper)}>
                    {turns}
                  </span>
                </div>
              </>
            )}
            {lifes != undefined && (
              <div className={classnames(styles.lifes)}>
                Dzīvības:
                <div
                  className={classnames(styles.life, {
                    [styles.lostLife]: lifes < 1,
                  })}
                ></div>
                <div
                  className={classnames(styles.life, {
                    [styles.lostLife]: lifes < 2,
                  })}
                ></div>
                <div
                  className={classnames(styles.life, {
                    [styles.lostLife]: lifes < 3,
                  })}
                ></div>
              </div>
            )}
          </div>
          <div className={styles.exitQuiz}>
            <GoBackButton
              linkTo={exitQuiz}
              text="Iziet no spēles"
              showArrowIcon={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
