import styles from "./index.module.scss";

type Props = {
  text?: string;
};

export const HazardTape = ({ text = "Izstrādē!" }: Props) => {
  return (
    <div className={styles.cautionLine}>
      <div className={styles.cautionText}>{text}</div>
    </div>
  );
};
