import React from "react";
import styles from "./index.module.scss";

export const SignInComponent = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>
          Esi viens <br></br>no mums <br></br>un kļūsti par<br></br>
          programmētāju!
        </h1>
      </div>
    </div>
  );
};
