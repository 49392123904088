import classnames from "classnames";
import Image from "next/legacy/image";
import styles from "./index.module.scss";

export type Props = {
  title: string;
  href?: string;
  disabled: boolean;
  svgSource: string;
  svgWidth: number;
  svgHeight: number;
  svgAlt: string;
  target: string;
  onClickOpenModal: () => void;
};

export const AuthButton = ({
  title,
  href,
  disabled,
  svgSource,
  svgHeight,
  svgWidth,
  svgAlt,
  target,
  onClickOpenModal,
}: Props) => {
  return (
    <a
      className={classnames(styles.customButton, {
        [styles.disabled]: disabled,
      })}
      type="submit"
      href={href}
      target={target}
      rel="noreferrer"
    >
      <div onClick={onClickOpenModal} className={styles.wrapper}>
        <Image
          className={styles.discordSvg}
          src={svgSource}
          alt={svgAlt}
          width={svgWidth}
          height={svgHeight}
        />
        <div>
          <span className={styles.buttonText}> Pievienoties</span>
          <br />
          {title}
        </div>
      </div>
    </a>
  );
};
