import Head from "next/head";

type Props = {
  title: string;
  description: string;
  image: string;
  url: string;
};

export const HeadComponent = ({ title, description, image, url }: Props) => {
  return (
    <Head>
      <title>
        {title.trim().length < 1 ? "Kļūsti par programmētāju | CODELEX" : title}
      </title>
      <meta name="description" content={description} />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#626a7e" />
      <meta name="msapplication-TileColor" content="#626a7e" />
      <meta name="theme-color" content="#626a7e" />

      {/*<!-- Google / Search Engine Tags -->*/}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />

      {/*<!-- Facebook Meta Tags -->*/}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/*<!-- Twitter Meta Tags -->*/}
      <meta
        name="twitter:title"
        content={
          title.trim().length < 1 ? "Kļūsti par programmētāju | CODELEX" : title
        }
      />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:card" content={url} />
    </Head>
  );
};
