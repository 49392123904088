import React, { FC } from "react";
import classnames from "classnames";
import VideoIcon from "@public/svg/video-icon.svg";
import ClockIcon from "@public/svg/clock.svg";
import styles from "./index.module.scss";

type Props = {
  length: string;
  count: number;
  title: string;
  onClick: () => void;
  lessonId: string;
  disabled: boolean;
};

export const CourseModuleItem: FC<Props> = ({
  count,
  length,
  title,
  onClick,
  lessonId,
  disabled,
}) => {
  const fixedCount = parseInt(lessonId, 10) < 2 ? count : count + 1;

  return (
    <button
      onClick={onClick}
      className={classnames(styles.container, {
        [styles.disabled]: disabled,
      })}
    >
      <div
        className={classnames(styles.topWrapper, {
          [styles.disabled]: disabled,
        })}
      >
        <div className={styles.numberWrapper}>
          <div
            className={classnames(styles.number, {
              [styles.currentLesson]: `0${fixedCount}` === lessonId,
            })}
          >
            {count}
          </div>
        </div>
        <div className={styles.lengthWrapper}>
          <div className={styles.videoIconWrapper}>
            <VideoIcon />
          </div>
          <div className={styles.lengthDescWrapper}>
            <ClockIcon />
            <div className={styles.lengthDesc}>{length}</div>
          </div>
        </div>
      </div>
      <div className={styles.bottomWrapper}>
        <div className={styles.lessonTitle}>{title}</div>
      </div>
    </button>
  );
};
