import React from "react";
import Image from "next/legacy/image";
import CopyIcon from "@public/svg/copyIcon.svg";
import styles from "./index.module.scss";

export type Props = {
  card: string;
  copyToClipboard: () => void;
  copyText?: string;
};

export const ReferralContentCard = ({
  card,
  copyText,
  copyToClipboard,
}: Props) => {
  const size = 43;
  return (
    <div className={styles.container}>
      <Image
        className={styles.messageIcon}
        src={"/svg/message.svg"}
        alt={"download image"}
        height={size}
        width={size}
      />

      <div onClick={copyToClipboard} className={styles.wrapper}>
        <div className={styles.descWrapper}>
          <p className={styles.desc}>{card}</p>
        </div>
        <div className={styles.copyWrapper}>
          <CopyIcon className={styles.copyIcon} />
          <span className={styles.copyText}>{copyText}</span>
        </div>
      </div>
    </div>
  );
};
