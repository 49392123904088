import { Radio } from "antd";
import styles from "./index.module.scss";

export type Props = {
  value: string | number;
  label: string | number;
};

export const RadioButton = ({ value, label }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Radio key={value} value={value}>
        {label}
      </Radio>
    </div>
  );
};
