import React, { FC } from "react";
import Link from "next/link";
import VideoIcon from "@public/svg/video-icon.svg";
import styles from "./index.module.scss";

type Props = {
  link: {
    href: string;
    title: string;
    description?: string;
    count: number;
  };
};
export const VideoTopicItem: FC<Props> = ({ link }) => {
  const { href, title, count, description } = link;

  return (
    <Link href={href}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.listCountWrapper}>
            <span className={styles.listCount}>{count}</span>
          </div>
          <div className={styles.title}>{title}</div>
          <div className={styles.videoIconWrapper}>
            <VideoIcon />
          </div>
        </div>
        {description && (
          <div className={styles.shortDescriptionText}>{description}</div>
        )}
      </div>
    </Link>
  );
};
