import { BuiltInProviderType } from "next-auth/providers";
import React, { FC } from "react";
import classNames from "classnames";
import { LiteralUnion } from "next-auth/react";
import { RightOutlined } from "@ant-design/icons";
import GoogleIcon from "@public/svg/google.svg";
import FacebookIcon from "@public/svg/facebook.svg";
import GithubIcon from "@public/svg/github.svg";
import { NextAuthProvider } from "@domain/auth/types";
import styles from "./index.module.scss";

type Props = {
  provider: LiteralUnion<BuiltInProviderType, string>;
  onClick: () => void;
  previousAuthMethod: NextAuthProvider | null;
};

export const NextAuthButton: FC<Props> = ({
  provider,
  onClick,
  previousAuthMethod,
}) => {
  const PreviousAuthButton = (
    <div className={styles.previousButtonWrapper}>
      <div className={styles.previousButton}>Iepriekš izmantots</div>
      <RightOutlined
        style={{
          fontSize: "14px",
          color: "#00204e",
          fontWeight: "bold",
          fontFamily: "var(--visby-bold)",
        }}
      />
    </div>
  );
  return (
    <>
      {provider === "google" && (
        <button className={styles.buttonWrapper} onClick={onClick}>
          <div
            className={classNames(styles.wrapper, {
              [styles.highLight]:
                previousAuthMethod === "google" || previousAuthMethod === null,
            })}
          >
            <div className={styles.iconWrapper}>
              <GoogleIcon />
            </div>
            <div className={styles.buttonText}>Turpināt ar Google</div>
            {previousAuthMethod === "google" ? (
              PreviousAuthButton
            ) : (
              <div className={styles.hallowPrevious}>&nbsp;</div>
            )}
          </div>
        </button>
      )}
      {provider === "facebook" && (
        <button className={styles.buttonWrapper} onClick={onClick}>
          <div
            className={classNames(styles.wrapper, {
              [styles.highLight]:
                previousAuthMethod === "facebook" ||
                previousAuthMethod === null,
            })}
          >
            <div className={styles.iconWrapper}>
              <FacebookIcon className={styles.facebookIcon} />
            </div>
            <div className={styles.buttonText}>Turpināt ar Facebook</div>
            {previousAuthMethod === "facebook" ? (
              PreviousAuthButton
            ) : (
              <div className={styles.hallowPrevious}>&nbsp;</div>
            )}
          </div>
        </button>
      )}
      {provider === "github" && (
        <button className={styles.buttonWrapper} onClick={onClick}>
          <div
            className={classNames(styles.wrapper, {
              [styles.highLight]:
                previousAuthMethod === "github" || previousAuthMethod === null,
            })}
          >
            <div className={styles.iconWrapper}>
              <GithubIcon className={styles.githubIcon} />
            </div>
            <div className={styles.buttonText}>Turpināt ar Github</div>
            {previousAuthMethod === "github" ? (
              PreviousAuthButton
            ) : (
              <div className={styles.hallowPrevious}>&nbsp;</div>
            )}
          </div>
        </button>
      )}
    </>
  );
};
