import React, { FC, useEffect, useState } from "react";
import classnames from "classnames";
import CountDownFrame from "@public/svg/count-down-frame.svg";
import { minutesToMillis, msToDuration } from "@utils/date-time/common";
import styles from "./index.module.scss";

type Props = {
  durationMs: number;
  onExpired: () => void;
};

interface RemainingTime {
  label: string;
  firstDigit: number;
  secondDigit: number;
}

const getInitialRemainingTime = (duration: Duration): RemainingTime => {
  const remainingDays = duration.days || 0;
  const remainingHours = duration.hours || 0;
  const remainingMinutes = duration.minutes || 0;
  const remainingSeconds = duration.seconds || 0;

  if (remainingDays > 0) {
    return {
      label:
        remainingDays === 1
          ? "diena līdz piedāvājuma beigām"
          : "dienas līdz piedāvājuma beigām",
      firstDigit: Math.floor(remainingDays / 10),
      secondDigit: remainingDays % 10,
    };
  } else if (remainingHours > 0) {
    return {
      label:
        remainingHours === 1
          ? "stunda līdz piedāvājuma beigām"
          : "stundas līdz piedāvājuma beigām",
      firstDigit: Math.floor(remainingHours / 10),
      secondDigit: remainingHours % 10,
    };
  } else if (remainingMinutes > 0) {
    return {
      label:
        remainingMinutes === 1
          ? "minūte līdz piedāvājuma beigām"
          : "minūtes līdz piedāvājuma beigām",
      firstDigit: Math.floor(remainingMinutes / 10),
      secondDigit: remainingMinutes % 10,
    };
  } else {
    return {
      label: "sekundes līdz piedāvājuma beigām",
      firstDigit: Math.floor(remainingSeconds / 10),
      secondDigit: remainingSeconds % 10,
    };
  }
};

export const DiscountCountdownAllTimes: FC<Props> = ({
  durationMs: initialDurationMs,
  onExpired,
}) => {
  const [durationMs, setDurationMs] = useState(initialDurationMs);
  const [duration, setDuration] = useState<Duration>({});
  const [remainingTime, setRemainingTime] = useState<RemainingTime>(
    getInitialRemainingTime(duration),
  );

  useEffect(() => {
    const updateCountdown = () => {
      if (durationMs <= 0) {
        setRemainingTime({
          label: "",
          firstDigit: 0,
          secondDigit: 0,
        });
        clearInterval(interval);
        onExpired();
        return;
      }
      const newDuration = durationMs - 1000;
      setDurationMs(newDuration);
      setDuration(msToDuration(newDuration));
      setRemainingTime(getInitialRemainingTime(duration));
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [durationMs]);

  return (
    <div className={styles.countDownWrapper}>
      <div className={styles.timeWrapper}>
        <div className={styles.time}>{remainingTime.label}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.wrapper} suppressHydrationWarning>
          <CountDownFrame />
          <div
            className={classnames(styles.number, {
              [styles.numberRed]: durationMs < minutesToMillis(30), //less than 30 minutes
            })}
            suppressHydrationWarning
          >
            {remainingTime.firstDigit}
          </div>
        </div>
        <div className={styles.wrapper} suppressHydrationWarning>
          <CountDownFrame />
          <div
            className={classnames(styles.number, {
              [styles.numberRed]: durationMs < minutesToMillis(30), //less than 30 minutes
            })}
            suppressHydrationWarning
          >
            {remainingTime.secondDigit}
          </div>
        </div>
      </div>
    </div>
  );
};
