import React, { FC } from "react";
import styles from "./index.module.scss";

type Props = {
  count: number;
  children?: React.ReactNode;
};

export const NumberedListItem: FC<Props> = ({ children, count }) => {
  return (
    <div className={styles.wrapper}>
      <ol>
        <li>
          <div className={styles.bulletWrapper}>
            <span className={styles.bullet}>{count}</span>
          </div>
          {children}
        </li>
      </ol>
    </div>
  );
};
