import { FC } from "react";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

type Props = {
  trackClick: () => void;
};

export const GameBanner: FC<Props> = ({ trackClick }) => {
  const router = useRouter();
  return (
    <div className={styles.wrapper}>
      <div>
        <h3 className={styles.title}>
          {" "}
          Pārbaudes mājasdarbs - loģikas spēles!{" "}
        </h3>
        <p className={styles.text}>
          Vai Tev patīk izaicinājumi un prāta vingrinājumi? Ja jā, tad šis
          pārbaudījums ir tieši Tev! Mēs piedāvājam divas spēles, kas pārbaudīs
          Tavas spējas problēmu risināšanā, loģiku un stratēģisko domāšanu. Un
          tas viss - bez jebkādām programmēšanas prasmēm.
        </p>
        <div className={styles.buttonWrapper}>
          <Button
            target="_self"
            rel="noreferrer"
            onClick={() => {
              trackClick();
              router.push("/introductory-course/games");
            }}
          >
            Spēlēt
          </Button>
        </div>
      </div>
      <div className={styles.logoWrapper}>
        <Image
          src={"/game-logo.png"}
          alt={"game logo"}
          width={214}
          height={188}
        />
      </div>
    </div>
  );
};
