import React, { FC } from "react";
import classnames from "classnames";
import Link from "next/link";
import CodelexIcon from "@public/svg/codelex-logo.svg";
import WarningIcon from "@public/svg/warning-green.svg";
import WebIcon from "@public/svg/web.svg";
import MailIcon from "@public/svg/mail-footer.svg";
import { CompanyInvoiceRequest } from "@domain/schedules/invoicing/types";
import { formatDateNative } from "@utils/date-time/common";
import styles from "./index.module.scss";

type Props = {
  invoice: CompanyInvoiceRequest;
};

export const InvoiceCompany: FC<Props> = ({ invoice }) => {
  const { amount, date, dueDate, id, issuer, recipient, wherefore } = invoice;

  const replaceCommaAndAddDecimal = (input: number): string => {
    const inputToString = input.toFixed(2);
    if (!inputToString.includes(".")) {
      return inputToString.concat(",00");
    }
    return inputToString.replace(".", ",");
  };

  const baseAmount = replaceCommaAndAddDecimal(amount.base);
  const taxAmount = replaceCommaAndAddDecimal(amount.vat);
  const totalAmount = replaceCommaAndAddDecimal(amount.total);

  return (
    <div className={styles.container}>
      <div className={styles.headWrapper}>
        <div className={styles.titleContainer}>
          <div className={styles.headTitle}>Rēķins</div>
          <div className={styles.invoiceNr}>{id}</div>
          <div className={styles.date}>
            <div className={styles.dateNow}>
              <div
                className={classnames(
                  styles.contentLabel,
                  styles.constantWidth,
                )}
              >
                Datums
              </div>
              <div className={styles.contentTitle}>
                {formatDateNative(date)}
              </div>
            </div>
            <div className={styles.dateDue}>
              <div className={styles.contentLabel}>Apmaksas termiņš</div>
              <div className={styles.contentTitle}>
                {formatDateNative(dueDate)}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.codelexLogoWrapper}>
          <CodelexIcon className={styles.codelexLogo} />
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.contentLeftSide}>
          <div className={styles.supplier}>
            <div className={styles.contentLabel}>Piegādātājs</div>
            <div className={styles.contentTitle}>{issuer.company}</div>
          </div>

          <div className={styles.accountWrapper}>
            <div className={styles.regNumber}>
              <div
                className={classnames(
                  styles.contentLabel,
                  styles.constantWidth,
                )}
              >
                Reģ. Nr.
              </div>
              <div className={styles.contentTitle}>
                {issuer.registrationNumber}
              </div>
            </div>

            <div className={styles.pvnNr}>
              <div className={styles.contentLabel}>PVN Maksātāja NR.</div>
              <div className={styles.contentTitle}>{issuer.vatNumber}</div>
            </div>
          </div>

          <div className={styles.address}>
            <div className={styles.contentLabel}>Adrese</div>
            <div className={styles.contentTitle}>{issuer.address}</div>
          </div>

          <div className={styles.bankWrapper}>
            <div className={styles.bank}>
              <div
                className={classnames(
                  styles.contentLabel,
                  styles.constantWidth,
                )}
              >
                Banka
              </div>
              <div className={styles.contentTitle}>{issuer.bank}</div>
            </div>

            <div className={styles.code}>
              <div className={styles.contentLabel}>Kods</div>
              <div className={styles.contentTitle}>{issuer.swift}</div>
            </div>
          </div>

          <div className={styles.account}>
            <div className={styles.contentLabel}>Konts</div>
            <div className={styles.contentTitle}>{issuer.accountNumber}</div>
          </div>
        </div>

        <div className={styles.contentRightSide}>
          <div className={styles.beneficiary}>
            <div className={styles.contentLabel}>Saņēmējs</div>
            <div className={styles.contentName}>{recipient.company}</div>
          </div>
          <div className={styles.date}>
            <div className={styles.dateDue}>
              <div className={styles.contentLabel}>Reģ. Nr.</div>
              <div className={styles.contentTitle}>
                {recipient.registrationNumber}
              </div>
            </div>
          </div>

          <div className={styles.date}>
            <div className={styles.dateNow}>
              <div
                className={classnames(
                  styles.contentLabel,
                  styles.constantWidth,
                )}
              >
                PVN Maksātāja NR.
              </div>
              <div className={styles.contentName}>{recipient.vatNumber}</div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.whereforeWrapper}>
        <div className={styles.wherefore}>{wherefore}</div>
        <div className={styles.whereforeSum}>{baseAmount} €</div>
      </div>
      <div className={styles.watWrapper}>
        <div className={styles.wherefore}>PVN 21 %</div>
        <div className={styles.whereforeSum}>{taxAmount} €</div>
      </div>

      <div className={styles.payDetails}>
        <div className={styles.payDetailsLeftSide}>
          <div className={styles.details}>
            <div className={styles.warningWrapper}>
              <WarningIcon />
            </div>
            <div className={styles.payTitle}>
              Maksājuma detaļās lūgums norādīt rēķina numuru:{" "}
              <span className={styles.highLight}>{id}</span>
            </div>
          </div>
        </div>
        <div className={styles.payDetailsRightSide}>
          <div className={styles.payLabel}>Kopā</div>
          <div className={styles.paySum}>{totalAmount} €</div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.navigationWrapper}>
          <div className={styles.navigationHomeWrapper}>
            <WebIcon className={styles.webIcon} />
            <div className={styles.webTitle}>
              <Link href={"https://www.codelex.io/"}>www.codelex.io</Link>
            </div>
          </div>
          <div className={styles.navigationInfoWrapper}>
            <MailIcon className={styles.mailIcon} />
            <div className={styles.mailTitle}>info@codelex.io</div>
          </div>
        </div>
      </div>
    </div>
  );
};
