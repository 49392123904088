import Link from "next/link";
import { FC } from "react";
import classnames from "classnames";
import { type ScheduleItem } from "@domain/schedules/ssr/fetch-user-schedule-items";
import styles from "./index.module.scss";

type Props = {
  invoiceDetails: ScheduleItem;
};

export const StudentInvoiceCard: FC<Props> = ({ invoiceDetails }) => {
  const { invoiceNumber, invoiceDate, amount, status, accessKey, wherefore } =
    invoiceDetails;
  return (
    <div className={styles.container}>
      <p className={styles.label}>Rēķins nr. </p>
      <p className={styles.item}>{invoiceNumber}</p>
      <div className={styles.topWrapper}>
        <div>
          <p className={styles.label}>Rēķina datums </p>
          <p className={styles.item}>{invoiceDate}</p>
        </div>
        <div className={styles.amountWrapper}>
          <p className={styles.label}>Summa</p>
          <p className={styles.item}>{amount} €</p>
        </div>
      </div>
      <Link
        href={`/invoices/${invoiceNumber}?access-key=${accessKey}`}
        target="_blank"
        className={classnames(styles.bottomWrapper, {
          [styles.disableOpenInvoiceLink]: !(
            invoiceNumber &&
            accessKey &&
            wherefore
          ),
        })}
      >
        <p
          className={classnames(styles.status, {
            [styles.statusDelayed]: status === "delayed",
            [styles.statusClosed]: status === "closed",
            [styles.statusOpened]: status === "opened",
          })}
        >
          Statuss
        </p>
        <p
          className={classnames(styles.invoiceStatus, {
            [styles.statusDelayed]: status === "delayed",
            [styles.statusClosed]: status === "closed",
            [styles.statusOpened]: status === "opened",
          })}
        >
          {status === "closed"
            ? "Samaksāts"
            : status === "delayed"
            ? "Nokavēts"
            : "Atvērts"}
        </p>
        {invoiceNumber && accessKey && wherefore && (
          <div className={styles.linkWrapper}>
            <u>Atvērt rēķinu</u>{" "}
          </div>
        )}
      </Link>
    </div>
  );
};
