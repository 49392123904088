import { Input } from "antd";
import styles from "./index.module.scss";

const { TextArea: AntDTextArea } = Input;

export type Props = {
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
};

export const TextArea = ({ placeholder, value = "", onChange }: Props) => {
  return (
    <div className={styles.wrapper}>
      <AntDTextArea
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
