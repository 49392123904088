import React from "react";
import HeartGreeting from "@public/svg/heart-greeting.svg";
import styles from "./index.module.scss";

export const SignInFooter = () => {
  return (
    <footer>
      <div className={styles.wrapper}>
        <p className={styles.footerText}>
          Ja reģistrācijas vai pierakstīšanās procesā rodas tehniski jautājumi,
          neskaidrības vai problēmas, lūdzu, nekautrējies sazinies, rakstot uz
          info@codelex.io.
        </p>{" "}
        <div className={styles.greetingWrapper}>
          <HeartGreeting />
        </div>
      </div>
    </footer>
  );
};
