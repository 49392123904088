import { Modal } from "antd";
import { IntroCourseLesson } from "@domain/intro-course/types";
import { CalendarSchedule } from "../CalendarSchedule";
import styles from "./index.module.scss";

export type Props = {
  isModalVisible: boolean;
  toggleModal: () => void;
  calendar: IntroCourseLesson[];
};

export const CalendarScheduleModal = ({
  isModalVisible,
  toggleModal,
  calendar,
}: Props) => {
  return (
    <Modal
      open={isModalVisible}
      onCancel={toggleModal}
      footer={null}
      className={styles.modal}
      width={632}
    >
      <div className={styles.calendarWrapper}>
        <CalendarSchedule calendar={calendar} />
      </div>
    </Modal>
  );
};
