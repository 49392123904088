import React, { FC } from "react";
import classNames from "classnames";
import ThumbsUp from "@public/svg/thumbs-up.svg";
import LightBulb from "@public/svg/light-bulb.svg";
import Danger from "@public/svg/danger.svg";
import styles from "./index.module.scss";

export type Props = {
  type: "success" | "info" | "danger";
  title: string;
  content?: string;
  button?: {
    text: string;
    onClick: () => void;
  };
};

export const MultiStageNotificationBanner: FC<Props> = ({
  type,
  title,
  content,
  button,
}) => {
  return (
    <div className={styles.wrapper}>
      {type === "success" && <ThumbsUp className={styles.icon} />}
      {type === "info" && <LightBulb className={styles.icon} />}
      {type === "danger" && <Danger className={styles.icon} />}
      <div
        className={classNames(styles.descContainer, {
          [styles.descContainerWithButton]: button,
        })}
      >
        <div className={styles.descWrapper}>
          <h4 className={styles.title}>{title}</h4>
          {content && <span className={styles.desc}>{content}</span>}
        </div>
        {button && (
          <button
            className={classNames({
              [styles.button]: button,
              [styles.success]: type === "success",
              [styles.info]: type === "info",
              [styles.danger]: type === "danger",
            })}
            onClick={button.onClick}
          >
            {button.text}
          </button>
        )}
      </div>
    </div>
  );
};
