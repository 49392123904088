import { Step } from "@domain/assignment-games/types";
import { HeaderLogo } from "../HeaderLogo";
import styles from "./index.module.scss";
import NextNavMenu from "./NextNavMenu";

export interface Props {
  stepper?: {
    key: Step;
    active: boolean;
    completed: boolean;
  }[];
}

export const NextNavMenuVertical = ({ stepper }: Props) => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <HeaderLogo link="/" />
        </div>
        {stepper &&
          stepper.map((item, i) => {
            return (
              <NextNavMenu
                item={item}
                i={i}
                objectLength={stepper.length}
                key={i}
              />
            );
          })}
      </div>
    </>
  );
};
