import { useState } from "react";
import Image from "next/image";
import { Form, message, Upload } from "antd";
import { axios } from "@integration/account-api";
import { Button } from "@components/atoms/Button";
import { fileExtension } from "@utils/filename";
import styles from "./index.module.scss";

type FormFields = {
  accountId: string;
};

export type Props = {
  uploadUrl?: string;
  uploadButtonTitle: string;
  uploadDisabled: boolean;
  refetch?: () => Promise<unknown> | undefined;
};

interface UploadResult {
  success: boolean;
  error?: string;
}

export const UploadAgreementContainer = ({
  uploadUrl,
  uploadButtonTitle,
  uploadDisabled,
  refetch,
}: Props) => {
  const [form] = Form.useForm<FormFields>();
  const [loading, setLoading] = useState(false);

  const onUpload = async ({ file }: { file: File }) => {
    if (uploadDisabled || !uploadUrl) {
      return;
    }
    const ext = fileExtension({ filename: file.name });
    if (ext !== "pdf") {
      message.error("Līgumam ir jābūt parakstītam pdf formātā");
      return;
    }
    const formData = new FormData();
    formData.append("agreements", file);
    setLoading(true);
    try {
      const { data } = await axios.post<UploadResult>(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data.success) {
        message.info("Līgums ir veiksmīgi augšuplādēts");
        setLoading(false);
        refetch ? refetch() : undefined;
      } else {
        message.error(
          "Augšupielāde neizdevās. Līgumam ir jābūt parakstītam pdf formātā",
        );
      }
    } catch (error) {
      message.error(
        `Darbība neizdevās. Ja problēma atkārtojas, sazinies ar mums rakstot uz info@codelex.io`,
      );
      setLoading(false);
    }
  };

  return (
    <Form form={form} name="agreement" autoComplete="off" layout="vertical">
      <Form.Item
        name="agreement"
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e?.fileList;
        }}
      >
        <Upload
          customRequest={(it) => {
            onUpload({ file: it.file as File });
          }}
          maxCount={1}
          showUploadList={false}
        >
          <div className={styles.btnWrapper}>
            <Button
              loading={loading}
              disabled={uploadDisabled || !uploadUrl}
              type="text"
              icon={
                <Image
                  src={"/svg/upload.svg"}
                  alt={"upload image"}
                  height={28}
                  width={30}
                />
              }
              data-testid={"upload-agreement"}
            >
              <div style={{ marginLeft: "8px" }}>{uploadButtonTitle}</div>
            </Button>
          </div>
        </Upload>
      </Form.Item>
    </Form>
  );
};
