import React, { FC, useEffect, useState } from "react";
import classNames from "classnames";
import CountDownFrame from "@public/svg/count-down-frame.svg";
import { msToDuration } from "@utils/date-time/common";
import styles from "./index.module.scss";

type Props = {
  expiresInMs: number;
  showRedCountDown: boolean;
};

export const DiscountCountdown: FC<Props> = ({
  expiresInMs,
  showRedCountDown,
}) => {
  const [duration, setDuration] = useState<Duration>({});

  useEffect(() => {
    setDuration(msToDuration(expiresInMs));
  }, [expiresInMs]);

  const time = {
    days: duration.days,
    hours: duration.hours,
  };

  const formatToDoubleDigits = (timeLeft: number) => {
    const format = timeLeft.toString();
    if (format.length === 1) {
      return `0${format}`;
    } else {
      return format;
    }
  };

  const splitDays = formatToDoubleDigits(time.days || 0).split("");
  const splitHours = formatToDoubleDigits(time.hours || 0).split("");

  const seperateDayDigits = {
    firstDigit: splitDays[0],
    secondDigit: splitDays[1],
  };

  const seperateHourDigits = {
    firstDigit: splitHours[0],
    secondDigit: splitHours[1],
  };

  return (
    <div className={styles.container}>
      <div className={styles.daysWrapper}>
        <div className={styles.wrapper}>
          <div className={styles.days}>dienas</div>
          <CountDownFrame />
          <div
            className={classNames(styles.number, {
              [styles.numberRed]: showRedCountDown,
            })}
            suppressHydrationWarning
          >
            {seperateDayDigits.firstDigit}
          </div>
        </div>
        <div className={styles.wrapper}>
          <CountDownFrame />
          <div
            className={classNames(styles.number, {
              [styles.numberRed]: showRedCountDown,
            })}
            suppressHydrationWarning
          >
            {seperateDayDigits.secondDigit}
          </div>
        </div>
      </div>
      <div className={styles.hoursWrapper}>
        <div className={styles.hours}>stundas</div>
        <div className={styles.gap}>&nbsp;</div>
        <div className={styles.wrapper}>
          <CountDownFrame />
          <div
            className={classNames(styles.number, {
              [styles.numberRed]: showRedCountDown,
            })}
            suppressHydrationWarning
          >
            {seperateHourDigits.firstDigit}
          </div>
        </div>
        <div className={styles.wrapper}>
          <CountDownFrame />
          <div
            className={classNames(styles.number, {
              [styles.numberRed]: showRedCountDown,
            })}
            suppressHydrationWarning
          >
            {seperateHourDigits.secondDigit}
          </div>
        </div>
      </div>
    </div>
  );
};
