import React, { FC } from "react";
import Link from "next/link";
import classnames from "classnames";
import VideoIcon from "@public/svg/video-icon.svg";
import AssignmentIcon from "@public/svg/codelex-logo.svg";
import styles from "./index.module.scss";

type Props = {
  link: {
    href: string;
    title: string;
    count: number;
    active?: boolean;
  };
  icon?: "video" | "assignment";
};
export const LessonOrTopicItem: FC<Props> = ({ link, icon = "video" }) => {
  const { href, title, count, active } = link;

  return (
    <Link href={href}>
      <div
        className={classnames(styles.container, {
          [styles.containerActive]: active,
        })}
      >
        <div className={styles.wrapper}>
          <div className={styles.listCountWrapper}>
            <span className={styles.listCount}>{count}</span>
          </div>
          <div className={styles.title}>{title}</div>
          <div className={styles.videoIconWrapper}>
            {icon == "video" && <VideoIcon />}
            {icon == "assignment" && <AssignmentIcon />}
          </div>
        </div>
      </div>
    </Link>
  );
};
