import classNames from "classnames";
import { Step } from "@domain/assignment-games/types";
import styles from "./index.module.scss";

type Props = {
  item: {
    key: Step;
    active: boolean;
    completed: boolean;
  };
  i: number;
  objectLength: number;
};

const name = ["Ievads", "Sagatavošanās", "NEXT"];

const NextNavMenu = ({ item, i, objectLength }: Props) => {
  return (
    <div
      className={classNames(styles.step, {
        [styles.stepActive]: item.active,
        [styles.stepCompleted]: item.completed,
      })}
    >
      {i === objectLength - 1 ? (
        <span className={styles.circleSmall}>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
            <path
              d="M4 7C2.34315 7 1 5.65685 1 4C1 2.34315 2.34315 1 4 1C5.65685 1 7 2.34315 7 4C7 5.65685 5.65685 7 4 7Z"
              fill="#131314"
              stroke="white"
            />
          </svg>
        </span>
      ) : (
        <span className={styles.circleWrapper}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <circle
              cx="9"
              cy="9"
              r="8"
              transform="rotate(90 9 9)"
              fill="#131314"
              stroke="white"
            />
            <path
              d="M13 7.92389L12.0146 7L7.93806 10.6962L5.97772 8.94113L5 9.8728L6.95262 11.6207C7.22706 11.8736 7.58782 12 7.94648 12C8.30514 12 8.6624 11.8749 8.93402 11.624L13 7.92389Z"
              fill="none"
            />
          </svg>
        </span>
      )}
      <span className={styles.titleWrapper}>{name[i]}</span>
      {i !== 0 && (
        <span className={styles.lineWrapper}>
          <svg width="2" height="43" viewBox="0 0 2 43" fill="none">
            <rect
              x="2"
              width="202"
              height="1.99999"
              rx="0.999996"
              transform="rotate(90 2 0)"
              fill="white"
            />
          </svg>
        </span>
      )}
    </div>
  );
};

export default NextNavMenu;
