import React, { FC } from "react";
import CodelexIcon from "@public/svg/codelex-logo.svg";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

type Props = {
  onClick: () => void;
};

export const VideoCourseBanner: FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.title}>TypeScript Pamati</div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.desc}>
          Padziļināts programmēšanas pamatu kurss, kura laikā izstrādāsi arī
          nelielus projektus
        </div>
        <div className={styles.actionWrapper}>
          <div className={styles.buttonWrapper}>
            <Button
              onClick={onClick}
              type="link"
              href="/courses/programming-basics-with-ts"
            >
              Atvērt
            </Button>
          </div>
          <div className={styles.iconWrapper}>
            <CodelexIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
