import React, { FC, ReactNode } from "react";
import { useRouter } from "next/router";
import classnames from "classnames";
import Image from "next/legacy/image";
import { Button } from "@components/atoms/Button";
import { HomeAssignmentType } from "@content/homework/types";
import { CountDown } from "../CountDown";
import styles from "./index.module.scss";

export type Props = {
  text: ReactNode;
  title: string;
  number: number;
  type: HomeAssignmentType;
  button: {
    title: string;
    titleToContinue: string;
    href: string;
    trackClick: () => void;
    disabled?: boolean;
  };
  waitingTime: number;
  quizState?: string;
};

export const HomeAssignmentBanner: FC<Props> = ({
  text,
  title,
  number,
  type,
  button,
  waitingTime,
  quizState,
}) => {
  const router = useRouter();
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.quizWrapper]: type !== "QUIZ",
      })}
      key={number}
    >
      {type === "QUIZ" && (
        <div className={styles.topWrapper}>
          <div className={styles.numberWrapper}>
            {" "}
            <span>{number}</span>
          </div>
          {waitingTime > 0 ? (
            <div className={styles.countDownText}>
              Testu varēs atkārtot pēc{" "}
              <CountDown
                onExpired={() => router.push("/start")}
                timeFormat={"mm:ss"}
                intervalMs={1000}
                initialDuration={waitingTime}
              />
            </div>
          ) : quizState === "IN_PROGRESS" ? (
            <p className={styles.quizStartedText}> TESTS IR IESĀKTS</p>
          ) : (
            <p className={styles.quizNotStartedText}> TESTS NAV IESĀKTS</p>
          )}
        </div>
      )}
      {type !== "QUIZ" && (
        <div className={styles.topWrapper}>
          <div className={styles.numberWrapper}>
            {" "}
            <span>{number}</span>
          </div>
          <h3
            className={classnames(styles.title, {
              [styles.gamesTitle]: type === "COGNITIVE_TEST",
            })}
          >
            {title}
          </h3>
        </div>
      )}
      {type === "QUIZ" && (
        <h3
          className={classnames(styles.title, {
            [styles.quizTitle]: type === "QUIZ",
          })}
        >
          {title}
        </h3>
      )}
      {type !== "COGNITIVE_TEST" && (
        <div
          className={classnames(styles.text, {
            [styles.additionalMargin]: type === "QUIZ",
            [styles.quizText]: type === "QUIZ",
          })}
        >
          {text}
        </div>
      )}
      {type === "COGNITIVE_TEST" && (
        <div className={styles.gamesLayout}>
          <div
            className={classnames(styles.text, {
              [styles.gamesText]: type === "COGNITIVE_TEST",
            })}
          >
            {text}
            <div className={styles.buttonWrapper}>
              <Button
                target="_self"
                rel="noreferrer"
                onClick={() => {
                  button.trackClick();
                  router.push("/introductory-course/games");
                }}
              >
                Spēlēt
              </Button>
            </div>
          </div>
          <div className={styles.logoWrapper}>
            <Image
              src={"/game-logo.png"}
              alt={"game logo"}
              width={201}
              height={176}
            />
          </div>
        </div>
      )}
      {type === "QUIZ" && (
        <div>
          <div
            className={classnames(styles.buttonWrapper, {
              [styles.additionalMargin]: type === "QUIZ",
            })}
          >
            <Button
              target="_self"
              rel="noreferrer"
              onClick={() => {
                button.trackClick();
                router.push(button.href);
              }}
              disabled={type === "QUIZ" && waitingTime > 0}
            >
              {quizState === "IN_PROGRESS"
                ? button.titleToContinue
                : button.title}
            </Button>
          </div>
        </div>
      )}
      {type === "typescript-course" && (
        <div
          className={classnames(styles.buttonWrapper, {
            [styles.additionalMargin]: type === "typescript-course",
          })}
        >
          <Button
            target="_self"
            rel="noreferrer"
            onClick={() => {
              button.trackClick();
              router.push(button.href);
            }}
          >
            {button.title}
          </Button>
        </div>
      )}
    </div>
  );
};
