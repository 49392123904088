const videoId = (src: string): string => {
  let id: string | undefined;
  if (src.indexOf("youtu.be") > -1 || src.indexOf("/embed/") > -1) {
    id = src.split("/").slice(-1).pop();
  } else {
    id = src.split("=").slice(-1).pop();
  }
  if (!id) {
    throw new Error(`Could not extract videoId from src=${src}`);
  }
  return id;
};

export const embeddedVideoSrc = ({ src }: { src: string }): string => {
  return `https://youtube.com/embed/${videoId(src)}`;
};

export const videoThumbnail = ({ src }: { src: string }): string => {
  return `https://img.youtube.com/vi/${videoId(src)}/0.jpg`;
};
