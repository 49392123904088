import Link from "next/link";
import classNames from "classnames";
import styles from "./index.module.scss";

export interface Props {
  menuItems: { title: string; path: string }[];
  pathName: string;
}

export const NavMenu = ({ menuItems, pathName }: Props) => {
  return (
    <div className={styles.wrapper}>
      {menuItems.map((item, i) => {
        return (
          <Link
            key={i}
            href={item.path}
            className={classNames(styles.link, {
              [styles.linkActive]:
                pathName === item.path ||
                pathName.substring(0, 35) === item.path,
            })}
          >
            {item.title}
          </Link>
        );
      })}
    </div>
  );
};
