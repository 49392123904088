import React from "react";
import Image from "next/legacy/image";
import styles from "./index.module.scss";

export interface Props {
  link: string;
  copyToClipboard: (link: string) => void;
}

export const ReferralCopyLink = ({ link, copyToClipboard }: Props) => {
  return (
    <div className={styles.wrapper} onClick={() => copyToClipboard(link)}>
      <div className={styles.copyWrapper} data-testid="referral-link">
        <div className={styles.description}>Tava unikālā saite</div>
        <div className={styles.link}>{link}</div>
      </div>
      <div className={styles.copyIcon}>
        <Image
          src={"/svg/copyIcon.svg"}
          alt="copy icon"
          width={19}
          height={20}
        />
      </div>
    </div>
  );
};
